import { Entity } from '../Entity';

export type CustomFieldDefinitionTypes = `text` | `boolean` | `numerical` | `categorical`;
export type CustomFieldDefinitionModels =
  | `task`
  | `client`
  | `vehicle`
  | `service_report_completed`
  | `service_report_task_completed`
  | `service_report_canceled`
  | `service_report_task_canceled`;

export interface CustomFieldDefinitionProps {
  custom_id: string;
  project_id: string;
  organization_id: string;
  label: string;
  field_type: CustomFieldDefinitionTypes;
  field_model: CustomFieldDefinitionModels;
  description?: string;
  multiple?: boolean;
  options?: string[];
  deleted?: boolean;
  created_by?: string;
  created_at?: Date;
  updated_at?: Date;
}

export class CustomFieldDefinition extends Entity<CustomFieldDefinitionProps> {
  get id(): string {
    return this._id!;
  }

  get custom_id(): string {
    return this.props.custom_id;
  }

  set custom_id(custom_id: string) {
    this.props.custom_id = custom_id;
  }

  get project_id(): string {
    return this.props.project_id;
  }

  get organization_id(): string {
    return this.props.organization_id;
  }

  get label(): string {
    return this.props.label;
  }
  set label(label: string) {
    this.props.label = label;
  }

  get field_type(): CustomFieldDefinitionTypes {
    return this.props.field_type;
  }

  set field_type(field_type: CustomFieldDefinitionTypes) {
    this.props.field_type = field_type;
  }

  get field_model(): CustomFieldDefinitionModels {
    return this.props.field_model;
  }
  set field_model(field_model: CustomFieldDefinitionModels) {
    this.props.field_model = field_model;
  }

  get description(): string | undefined {
    return this.props.description;
  }
  set description(description: string | undefined) {
    this.props.description = description;
  }

  get multiple(): boolean | undefined {
    return this.props.multiple;
  }

  set multiple(multiple: boolean | undefined) {
    this.props.multiple = multiple;
  }

  get options(): string[] | undefined {
    return this.props.options;
  }

  set options(options: string[] | undefined) {
    this.props.options = options;
  }

  get created_by(): string | undefined {
    return this.props.created_by;
  }

  set created_by(created_by: string | undefined) {
    this.props.created_by = created_by;
  }

  get created_at(): Date | undefined {
    return this.props.created_at;
  }

  get updated_at(): Date | undefined {
    return this.props.updated_at;
  }

  private constructor(props: CustomFieldDefinitionProps, id?: string) {
    super(props, id);
  }

  static create(props: CustomFieldDefinitionProps, id?: string): CustomFieldDefinition {
    return new CustomFieldDefinition(props, id);
  }
}
