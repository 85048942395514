import { PaginatedResult } from '@nx-smartmonkey/shared/interfaces';
import { Project } from '../domain/project/Project';
import { SearchPredicate } from '../domain/searchPredicates/SearchPredicate';
import { Stop } from '../domain/stop/Stop';
import { StopFlat } from '../domain/stop/StopFlat';
import { stopRepository } from '../repositories/stop';
import { StopRepository } from '../repositories/stop/StopRepository';

export class StopService {
  constructor(private stopRepository: StopRepository) {}

  async fetchStop({ stopId, project }: { stopId: string; project: Project }): Promise<Stop> {
    const stop = await this.stopRepository.fetchStop({ stopId, project });
    return stop;
  }

  async fetchSomeStops({ stopIds, project }: { stopIds: string[]; project: Project }): Promise<Array<Stop | null>> {
    const stops = await this.stopRepository.fetchSomeStops({ stopIds, project });
    return stops;
  }

  async search({
    project,
    offset,
    limit,
    sort_by,
    sort_direction,
    predicates,
    isExampleURL = false,
  }: {
    project: Project;
    offset?: number;
    limit?: number;
    sort_by?: string;
    sort_direction?: string;
    predicates: Array<SearchPredicate>;
    isExampleURL?: boolean;
  }): Promise<PaginatedResult<Stop>> {
    if (isExampleURL) {
      const stopsPagination = await this.stopRepository.searchExample(project);
      return stopsPagination;
    }

    const stopsPagination = await this.stopRepository.search({
      project,
      offset,
      limit,
      sort_by,
      sort_direction,
      predicates,
    });

    return stopsPagination;
  }

  async fetchFlatStops({
    projectId,
    predicates,
    isExampleURL = false,
  }: {
    projectId: string;
    predicates: Array<SearchPredicate>;
    isExampleURL?: boolean;
  }): Promise<Array<StopFlat>> {
    if (isExampleURL) {
      const flatStops = await this.stopRepository.fetchExampleFlatStops();
      return flatStops;
    }

    const flatStops = await this.stopRepository.fetchFlatStops({
      projectId,
      predicates,
    });

    return flatStops;
  }
}

export const stopService = new StopService(stopRepository);
