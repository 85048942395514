import { CustomFieldDefinition } from '@nx-smartmonkey/shared/domain';
import { ProjectCustomFieldDefinitionDTO } from '../../domain/project/ProjectDTO';

export class ProjectCustomFieldDefinitionMapper {
  static toDomain(props: ProjectCustomFieldDefinitionDTO): CustomFieldDefinition {
    return CustomFieldDefinition.create({
      custom_id: props.custom_id,
      project_id: props.project_id,
      organization_id: props.organization_id,
      label: props.label,
      field_type: props.field_type,
      field_model: props.field_model,
      description: props.description,
      multiple: props.multiple,
      options: props.options,
      created_by: props.created_by,
      updated_at: props.updated_at,
      created_at: props.created_at,
    });
  }

  static toDTO(customField: CustomFieldDefinition): ProjectCustomFieldDefinitionDTO {
    return {
      // id: customField.id,
      custom_id: customField.custom_id,
      project_id: customField.project_id,
      organization_id: customField.organization_id,
      label: customField.label,
      field_type: customField.field_type,
      field_model: customField.field_model,
      description: customField.description,
      multiple: customField.multiple,
      options: customField.options,
      created_by: customField.created_by,
      updated_at: customField.updated_at,
      created_at: customField.created_at,
    };
  }
}
