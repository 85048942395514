import { Chip, Grid } from '@mui/material';
import { RoutalLoader, VSpacer } from '@nx-smartmonkey/ui';

import { parseSecondsToHM } from '@nx-smartmonkey/shared/helpers';
import { RatingStars } from '../../../components/RatingStars';
import { getStopSurveyRatingTagInfo } from '../../../components/StopRatingTag';
import { CustomFields } from '../../../components/custom-fields/CustomFields';
import { Project } from '../../../domain/project/Project';
import { Stop } from '../../../domain/stop/Stop';
import { Supervisor } from '../../../domain/supervisor/Supervisor';
import { Text } from '../../../react-components/Text';
import { InfoContainer } from './RightPanel.styled';
import StopReport from './StopReport';

interface StopBodyTabsProps {
  stop: Stop;
  project: Project;
  supervisor: Supervisor;
  tab?: number;
}

export const StopBodyTabs = ({ stop, project, supervisor, tab }: StopBodyTabsProps) => {
  if (tab === undefined) {
    return (
      <Grid container justifyContent="center" style={{ height: `100%`, overflow: `hidden` }} alignItems="center">
        <RoutalLoader width={60} />
      </Grid>
    );
  }

  const stopCustomFields = project.custom_fields?.client.filter((cF) =>
    supervisor.isStopCustomFieldEnabled(cF.custom_id)
  );

  switch (tab) {
    case 0:
      return (
        <div style={{ height: `100%`, overflowY: `auto`, minHeight: `300px` }}>
          <InfoContainer>
            <Text variant="h6" isBold intlId="stop.group.contact" />

            <VSpacer small />

            {[`reference_person`, `email`, `phone`, `url`, `comments`, `customer_text_field`].map((field) => {
              if (!supervisor.isStopFieldEnabled(field)) {
                return null;
              }

              const fieldValue = (stop.props as Record<string, any>)[field];

              return (
                <>
                  <Text variant="label" intlId={`stop.${field}`} />
                  <VSpacer small />
                  <Text>{fieldValue !== undefined ? fieldValue : `-`}</Text>
                  <VSpacer small />
                </>
              );
            })}
          </InfoContainer>

          {stopCustomFields.length > 0 ? (
            <InfoContainer>
              <Text variant="h6" isBold intlId="stop.group.custom_fields" />

              <VSpacer small />

              <CustomFields readOnly customFields={stop.custom_fields} projectCustomFields={stopCustomFields} />
              <VSpacer small />
            </InfoContainer>
          ) : null}
        </div>
      );
    case 1:
      return (
        <div style={{ height: `100%`, overflowY: `auto`, minHeight: `300px` }}>
          <InfoContainer>
            <Text variant="h6" isBold intlId="stop.group.constraints" />

            <VSpacer small />

            {[`time_windows`, `max_delivery_time`, `reward`, `duration`, `weight`, `volume`, `requires`, `cluster`].map(
              (field) => {
                if (!supervisor.isStopFieldEnabled(field)) {
                  return null;
                }

                let content: React.ReactElement | React.ReactElement[] = <Text>{`-`}</Text>;

                const fieldValue = (stop.props as Record<string, any>)[field];

                if (fieldValue !== undefined) {
                  switch (field) {
                    case `time_windows`: {
                      const numericToText = (timeWindow: any) =>
                        `${parseSecondsToHM(timeWindow[0])}-${parseSecondsToHM(timeWindow[1])}`;

                      content = fieldValue.map((timeWindow: any, idx: any) => (
                        <Chip
                          style={{
                            marginLeft: idx !== 0 ? `8px` : 0,
                            height: `34px`,
                          }}
                          key={`time_window-${idx}`}
                          label={numericToText(timeWindow)}
                        />
                      ));
                      break;
                    }
                    case `max_delivery_time`:
                    case `duration`: {
                      content = <Text>{`${fieldValue / 60} min`}</Text>;
                      break;
                    }
                    case `weight`:
                    case `volume`: {
                      content = <Text>{`${fieldValue} ${project.units[field]}`}</Text>;
                      break;
                    }
                    case `requires`:
                      content = fieldValue.map((require: any, idx: any) => (
                        <Chip
                          style={{
                            marginLeft: idx !== 0 ? `8px` : 0,
                            height: `34px`,
                          }}
                          key={`requires-${idx}`}
                          label={require}
                        />
                      ));
                      break;
                    case `reward`:
                    case `cluster`:
                    default:
                      content = <Text>{fieldValue ?? `-`}</Text>;
                      break;
                  }
                }

                return (
                  <>
                    <Text variant="label" intlId={`stop.${field}`} />
                    <VSpacer small />
                    {content}
                    <VSpacer small />
                  </>
                );
              }
            )}
          </InfoContainer>
          <VSpacer large />
        </div>
      );
    case 2:
      return (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          style={{ height: `100%`, width: `100%`, overflowY: `auto`, minHeight: `300px`, overflowX: `hidden` }}
        >
          <Grid container direction="column" wrap="nowrap" style={{ height: `100%`, width: `100%`, overflowY: `auto` }}>
            <StopReport project={project} stop={stop} supervisor={supervisor} />
          </Grid>
        </Grid>
      );
    case 3:
      return (
        <div style={{ height: `100%`, overflowY: `auto`, minHeight: `300px` }}>
          {supervisor.survey_fields.length > 0 && stop.survey ? (
            <InfoContainer>
              <Text variant="h6" isBold intlId="stop.survey" />

              <VSpacer small />

              {supervisor.isSurveyFieldEnabled(`rating`) ? (
                <>
                  <Text variant="label" intlId="stop.survey.rating" />

                  <VSpacer small />

                  <RatingStars rating={stop.survey!.rating ?? 0} />

                  <VSpacer medium />
                </>
              ) : null}

              {supervisor.isSurveyFieldEnabled(`rating_tag`) ? (
                <>
                  <Text variant="label" intlId="stop.survey.rating_tag" />

                  <VSpacer small />

                  {getStopSurveyRatingTagInfo(stop.survey)}

                  <VSpacer medium />
                </>
              ) : null}

              {supervisor.isSurveyFieldEnabled(`rating_tag`) ? (
                <>
                  <Text variant="label" intlId="stop.survey.comments" />

                  <VSpacer small />

                  <Text style={{ wordBreak: `break-word` }}>{stop.survey.comments}</Text>

                  <VSpacer medium />
                </>
              ) : null}
            </InfoContainer>
          ) : (
            <Grid container justifyContent="center" alignItems="center" style={{ height: `80px` }}>
              <Text intlId="stop.no_survey" style={{ fontSize: `14px` }} />
            </Grid>
          )}
        </div>
      );
    default:
      return null;
  }
};
