import { Entity, HexColor, Language } from '@nx-smartmonkey/shared/domain';
import { removeEmpty } from '@nx-smartmonkey/shared/helpers';
import { SupervisorEntityField } from './SupervisorEntityField';
import {
  SupervisorStopCanceledType,
  SupervisorStopCompletedType,
  SupervisorStopFieldType,
  SupervisorStopSurveyType,
} from './SupervisorFields';

export interface SupervisorUpdateProps {
  language?: Language;
}

export interface SupervisorProps {
  project_id: string;
  organization_id: string;
  email: string;
  short_id: string;
  name: string;
  company: string;
  phone: string;
  color?: HexColor;
  language?: Language;

  stop_fields: Array<SupervisorEntityField<SupervisorStopFieldType>>;
  stop_completed_fields: Array<SupervisorEntityField<SupervisorStopCompletedType>>;
  stop_canceled_fields: Array<SupervisorEntityField<SupervisorStopCanceledType>>;
  survey_fields: Array<SupervisorEntityField<SupervisorStopSurveyType>>;

  // CRUD
  created_by?: string;
  created_at?: Date;
  updated_at?: Date;
}

export class Supervisor extends Entity<SupervisorProps> {
  get id(): string {
    return this._id!;
  }
  get project_id(): string {
    return this.props.project_id;
  }
  get organization_id(): string {
    return this.props.organization_id;
  }
  get email(): string {
    return this.props.email;
  }
  get short_id(): string {
    return this.props.short_id;
  }
  get name(): string {
    return this.props.name;
  }
  get company(): string {
    return this.props.company;
  }
  get phone(): string {
    return this.props.phone;
  }
  get color(): HexColor | undefined {
    return this.props.color;
  }
  get language(): Language | undefined {
    return this.props.language;
  }
  get stop_fields(): Array<SupervisorEntityField<SupervisorStopFieldType>> {
    return this.props.stop_fields;
  }
  private getStopField(
    id: string | SupervisorStopFieldType,
    isCustomField: boolean
  ): SupervisorEntityField<SupervisorStopFieldType> | undefined {
    return this.stop_fields.find((field) => field.id === id && field.is_custom_field === isCustomField);
  }
  isStopFieldEnabled(id: string | SupervisorStopFieldType): boolean {
    const field = this.getStopField(id, false);
    return Boolean(field);
  }
  isStopCustomFieldEnabled(id: string | SupervisorStopFieldType): boolean {
    const field = this.getStopField(id, true);
    return Boolean(field);
  }
  get stop_completed_fields(): Array<SupervisorEntityField<SupervisorStopCompletedType>> {
    return this.props.stop_completed_fields;
  }
  private getStopCompletedField(
    id: string | SupervisorStopCompletedType,
    isCustomField: boolean
  ): SupervisorEntityField<SupervisorStopCompletedType> | undefined {
    return this.stop_completed_fields.find((field) => field.id === id && field.is_custom_field === isCustomField);
  }
  isStopCompletedFieldEnabled(id: string | SupervisorStopCompletedType): boolean {
    const field = this.getStopCompletedField(id, false);
    return Boolean(field);
  }
  isStopCompletedCustomFieldEnabled(id: string | SupervisorStopCompletedType): boolean {
    const field = this.getStopCompletedField(id, true);
    return Boolean(field);
  }
  get stop_canceled_fields(): Array<SupervisorEntityField<SupervisorStopCanceledType>> {
    return this.props.stop_canceled_fields;
  }
  private getStopCanceledField(
    id: string | SupervisorStopCanceledType,
    isCustomField: boolean
  ): SupervisorEntityField<SupervisorStopCanceledType> | undefined {
    return this.stop_canceled_fields.find((field) => field.id === id && field.is_custom_field === isCustomField);
  }
  isStopCanceledFieldEnabled(id: string | SupervisorStopCanceledType): boolean {
    const field = this.getStopCanceledField(id, false);
    return Boolean(field);
  }
  isStopCanceledCustomFieldEnabled(id: string | SupervisorStopCanceledType): boolean {
    const field = this.getStopCanceledField(id, true);
    return Boolean(field);
  }
  get survey_fields(): Array<SupervisorEntityField<SupervisorStopSurveyType>> {
    return this.props.survey_fields;
  }
  private getSurveyField(
    id: string | SupervisorStopSurveyType
  ): SupervisorEntityField<SupervisorStopSurveyType> | undefined {
    return this.survey_fields.find((field) => field.id === id);
  }
  isSurveyFieldEnabled(id: string | SupervisorStopSurveyType): boolean {
    const field = this.getSurveyField(id);
    return Boolean(field);
  }
  get created_at(): Date | undefined {
    return this.props.created_at;
  }
  get created_by(): string | undefined {
    return this.props.created_by;
  }
  get updated_at(): Date | undefined {
    return this.props.updated_at;
  }
  update(props: SupervisorUpdateProps) {
    removeEmpty(props);

    if (props.language !== undefined) this.props.language = props.language;

    this.props.updated_at = new Date();
  }
  private constructor(props: SupervisorProps, id?: string) {
    super(props, id);
  }

  static create(props: SupervisorProps, id?: string): Supervisor {
    return new Supervisor(props, id);
  }
}
